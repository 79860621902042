import permissions from '@/common/utils/permissions/constants';

export const ENITITY_MODEL = {
  PARTY: 'party',
  DEPARTMENT: 'department',
  DEPARTMENT_ROLE: 'department_role',
  CREW_RANK: 'crew_rank'
};

export const ENTITY_BASE_PATH = {
  PARTY: 'persons',
  DEPARTMENT: 'department',
  DEPARTMENT_ROLE: 'department-roles',
  CREW_RANK: 'crew-ranks'
};

export const ENITITY_MODELS = Object.values(ENITITY_MODEL);

export const DETAIL_TYPES = [
  'vessels',
  'event_types',
  'forms',
  'purchasing_categories',
  'form_status_progresses',
  'crew_ranks'
];

export const getSortedEntities = (parentLabel: string, id: string) => {
  switch (parentLabel) {
    case permissions.office.purchasing.requisitions.items.view: {
      const values = Object.values(permissions.office.purchasing.requisitions.items);

      return values.indexOf(id);
    }

    case permissions.onboard.purchasing.requisitions.default: {
      const values = Object.values(permissions.onboard.purchasing.requisitions);

      return values.indexOf(id);
    }

    default:
      return id;
  }
};
