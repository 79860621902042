import ReactDOM from 'react-dom';
import { PropsWithChildren, FC, useEffect, useRef } from 'react';
import { SerializedStyles } from '@emotion/react';

import usePortal from '@/ts-common/utils/hooks/usePortal.ts';
import SvgRender from '@/ts-common/components/general/SvgRender';
import closeIcon from '@/ts-common/assets/svg/actions/close.svg';

type DrawerProps = PropsWithChildren<{
  isOpen: boolean;
  close?: () => void;
  className?: string;
  style?: React.CSSProperties;
  size?: 'sm' | 'sm-md' | 'md' | 'md-lg' | 'lg';
  css?: SerializedStyles;
}>;

const Drawer: FC<DrawerProps> = ({
  isOpen,
  close,
  children,
  className = '',
  style,
  size = 'sm',
  css
}) => {
  const target = usePortal('drawer-container');
  const ref = useRef(null);

  useEffect(() => {
    const openedDrawers = document.querySelectorAll('.drawer.open');

    if (!isOpen && !openedDrawers?.length) {
      document.body.classList.remove('drawer-open');
    } else if (isOpen) {
      document.body.classList.add('drawer-open');
    }
  }, [isOpen]);

  return ReactDOM.createPortal(
    <div
      ref={ref}
      id="drawer"
      className={`drawer drawer-size--${size} ${isOpen ? 'open' : ''} ${className}`}
      style={style}
      css={css} // eslint-disable-line
    >
      <div id="drawer__close" className="drawer__close cursor-pointer me-4" onClick={close}>
        <SvgRender src={closeIcon} style={{ width: 14, height: 14 }} className="pe-none" />
      </div>
      {children}
    </div>,
    target
  );
};
export default Drawer;

type DrawerComponentProps = PropsWithChildren<{ className?: string }>;

export const DrawerHeader = ({ children, ...rest }: DrawerComponentProps) => (
  <div id="drawer__header" {...rest}>
    {children}
  </div>
);

export const DrawerBody = ({ children, ...rest }: DrawerComponentProps) => (
  <div id="drawer__body" className="gray-scrollbar  overflow-x-hidden" {...rest}>
    {children}
  </div>
);
export const DrawerFooter = ({ children, ...rest }: DrawerComponentProps) => (
  <div id="drawer__footer" {...rest}>
    {children}
  </div>
);
