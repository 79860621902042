import {
  getDefaultCrewMatrix,
  getVesselCrewMatrices,
  setDefaultCrewMatrix,
  setSelectedMatrix,
  getVesselCrewMatrix,
  initializeMatrices
} from './actions';
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { normalizeRules } from './helpers';
import { LOCATION_CHANGE } from 'connected-react-router';
import paths from '@/routing/routes/_paths';

export const INITIAL_STATE = {
  defaultMatrix: null,
  matrices: [],

  isLoading: false,
  isLoadingSelectedMatrix: false,

  selectedMatrix: null,

  selectedMatrixRuleIds: [], // ["id1", "id2", "id3"]
  selectedMatrixRules: {}, // {id1: {...}, id2: {...}}

  selectedRuleTypeIds: [], // [1, 3, 5, 2]
  selectedRuleTypeDetails: {}, // {1: {...}, 3: {...}}
  selectedRuleTypesAsignedRules: {}, // {1: [ruleId1, ruleId3], 3: [ruleId2, ruleId7, ruleId6]}
  isInPlanning: false
};

const slice = createSlice({
  name: 'crew_matrices',
  initialState: INITIAL_STATE,
  extraReducers: builder => {
    builder
      .addCase(getDefaultCrewMatrix.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.defaultMatrix = payload ? payload : null;

        return state;
      })

      .addCase(getDefaultCrewMatrix.rejected, state => {
        state.isLoading = false;
        state.defaultMatrix = null;

        return state;
      })

      .addCase(getVesselCrewMatrices.fulfilled, (state, { payload }) => {
        state.isLoading = false;

        if (state.defaultMatrix) {
          const defaultMatrix = payload.find(matrix => matrix.id === state.defaultMatrix.id);

          state.matrices = [
            { ...defaultMatrix, isDefault: true },
            ...payload.filter(matrix => matrix.id !== state.defaultMatrix.id)
          ];
        } else {
          state.matrices = payload;
        }

        return state;
      })

      .addCase(initializeMatrices, state => {
        state.selectedMatrix = state.defaultMatrix;
        state.matrices = [];
        state.selectedMatrixRuleIds = [];
        state.selectedMatrixRules = {};
        state.selectedRuleTypeIds = [];
        state.selectedRuleTypeDetails = {};
        state.selectedRuleTypesAsignedRules = {};

        return state;
      })

      .addCase(setSelectedMatrix.pending, state => {
        state.isLoadingSelectedMatrix = true;

        return state;
      })

      .addCase(setSelectedMatrix.rejected, state => {
        state.isLoadingSelectedMatrix = false;

        return state;
      })

      .addCase(setDefaultCrewMatrix.fulfilled, (state, { payload }) => {
        state.defaultMatrix = payload;

        const defaultMatrix = state.matrices.find(matrix => matrix.id === payload.id);

        state.matrices = [
          { ...defaultMatrix, isDefault: true },
          ...state.matrices.reduce((prev, matrix) => {
            if (matrix.id !== state.defaultMatrix.id) {
              const { isDefault, ...rest } = matrix; // we make all of them non-default because we just assinged a new default and we put it first

              prev.push(rest);
            }

            return prev;
          }, [])
        ];

        return state;
      })
      .addCase(LOCATION_CHANGE, (state, { payload }) => {
        if (payload.location.pathname.startsWith(paths.crew_planning)) {
          state.isInPlanning = true;
        } else {
          state.isInPlanning = false;
        }
        return state;
      })

      .addMatcher(
        isAnyOf(setSelectedMatrix.fulfilled, getVesselCrewMatrix.fulfilled),
        (state, { payload }) => {
          state.isLoadingSelectedMatrix = false;
          state.selectedMatrix = payload;

          const { ruleIds, selectedRules, ruleTypeIds, ruleTypesAssigned, ruleTypeDetails } =
            normalizeRules(payload?.rules || []);

          state.selectedMatrixRuleIds = ruleIds;
          state.selectedMatrixRules = selectedRules;
          state.selectedRuleTypeIds = ruleTypeIds;
          state.selectedRuleTypesAsignedRules = ruleTypesAssigned;
          state.selectedRuleTypeDetails = ruleTypeDetails;

          return state;
        }
      )

      .addMatcher(isAnyOf(getDefaultCrewMatrix.pending, getVesselCrewMatrices.pending), state => {
        state.isLoading = true;

        return state;
      });
  }
});

export const { setSelectedMatrixId } = slice.actions;

export default slice.reducer;
