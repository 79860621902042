import { EntityLog, LOGS_ENTITY_TYPES } from '@/common/types/logs';
import { ReactNode } from 'react';
import { capitalizeFirst } from '@/common/utils/texts';

const parsingActionName = (serviceAction: string) => {
  switch (serviceAction) {
    case 'created_one_or_bulk':
      return 'created';
    case 'triggered_review_process':
      return 'triggered approval process';
    default:
      return serviceAction;
  }
};

const crewPlanningParsingActionName = (serviceAction: string) => {
  switch (serviceAction) {
    case 'crew_rotation_switch':
      return 'switch crew';
    case 'deleted':
      return 'revert switch';
    default:
      return serviceAction;
  }
};

const purchasingRequisitionParsingActionName = (
  serviceAction: string,
  serviceSubCategory: string
) => {
  switch (serviceAction) {
    case 'created':
      if (serviceSubCategory === 'requisition_supplier') {
        return 'assign supplier';
      }

      return serviceAction;

    case 'forwarding_enabled':
      return 'Sent to Forwarding';

    case 'updated':
      if (serviceSubCategory === 'requisition_item') {
        return `${serviceAction} items`;
      } else if (serviceSubCategory === 'requisition_supplier') {
        return `${serviceAction} supplier values/status`;
      }
      return serviceAction;
    case 'crew_rotation_switch':
      return 'switch crew';
    case 'deleted':
      if (serviceSubCategory === 'requisition_supplier') {
        return 'delete supplier';
      }
      return 'delete requisition';
    default:
      return serviceAction;
  }
};

const parsingEntityName = (serviceAction: string) => {
  switch (serviceAction) {
    case 'purchasing_requisition_supplier':
      return 'purchasing';
    case 'event_m_accounts':
      return 'Events - Accounts';
    case 'crew_medical_case_expenses':
      return 'Events - Crew Medical Cases';
    case 'ticket_offer':
      return 'ticketing';
    case 'port_da_case_agent':
      return 'DA - Agents';
    case 'port_da_case_agent_item':
      return 'DA - Direct Expenses';
    default:
      return serviceAction;
  }
};

const fieldKeys = { on_signer_id: 'Onsigner', date_of_change: 'Date of Change', port_id: 'Port' };

export const constructSentence = (entityLog: EntityLog): ReactNode => {
  const { service_category, service_action, service_subcategory, created_by, created_by_id } =
    entityLog;
  const serviceCategoryFormatted = service_category.replaceAll('_', ' ');
  const serviceActionFormatted = parsingActionName(service_action).replaceAll('_', ' ');
  const serviceEntities = entityLog?.entities || [];
  const changedAttributes = entityLog.changed_attributes || [];
  const fullName = created_by?.full_name || (created_by_id ? 'Anonymous User' : null);
  const statusName = entityLog?.entity_metadata?.display_name;

  if (service_action === 'status_updated') {
    return statusName ? (
      <span>
        {fullName} <strong>updated the status</strong> to '{statusName}'
      </span>
    ) : (
      <span>
        {fullName} <strong>updated the status</strong> of the {serviceCategoryFormatted}
      </span>
    );
  } else if (service_action === 'created_subsystem') {
    return (
      <span>
        {fullName} <strong>created</strong> a subsystem
      </span>
    );
  } else if (service_category === 'purchasing') {
    const action = purchasingRequisitionParsingActionName(
      service_action,
      service_subcategory
    ).replaceAll('_', ' ');

    return (
      <span>
        {fullName} <strong>{action}</strong>{' '}
        {service_action === 'created' || service_action === 'forwarding_enabled'
          ? ` the requisition`
          : null}
      </span>
    );
  } else if (
    service_category === 'job_trigger_prototypes' &&
    (service_action === 'created' || service_action === 'updated')
  ) {
    return (
      <span>
        {fullName} <strong>{serviceActionFormatted}</strong> a job
      </span>
    );
  } else if (
    (service_action === 'created' || service_action === 'updated') &&
    service_category === 'spare_part'
  ) {
    return (
      <span>
        {fullName} <strong>{serviceActionFormatted}</strong> a {serviceCategoryFormatted}
      </span>
    );
  } else if (service_category === LOGS_ENTITY_TYPES.party) {
    return (
      <span>
        {fullName} <strong>{serviceActionFormatted}</strong> the {service_subcategory}
      </span>
    );
  } else if (service_category === LOGS_ENTITY_TYPES.invoice && service_action === 'linked_cases') {
    const entity = serviceEntities?.[0];
    const caseName = parsingEntityName(entity?.entity_type);
    return (
      <span>
        {fullName} <strong>linked {caseName} cases</strong>.
      </span>
    );
  } else if (service_category === 'crew' && service_subcategory === 'contract') {
    return (
      <span>
        {fullName} <strong>{serviceActionFormatted}</strong> the crew contract
      </span>
    );
  } else if (
    service_category === LOGS_ENTITY_TYPES.invoice &&
    service_action === 'sent_to_accounting'
  ) {
    return (
      <span>
        {fullName} <strong>sent the invoice to accounting</strong>.
      </span>
    );
  } else if (
    service_category === LOGS_ENTITY_TYPES.invoice &&
    (service_action === 'reviewed' || service_action === 'unreviewed')
  ) {
    const serviceAction = service_action === 'reviewed' ? 'marked' : 'unmarked';

    return (
      <span>
        {fullName} <strong>{serviceAction}</strong> the invoice as good for payment.
      </span>
    );
  } else if (service_category === LOGS_ENTITY_TYPES.event && service_subcategory === 'module') {
    const serviceAction = service_action === 'created' ? 'added' : service_action;

    return (
      <span>
        {fullName} <strong>{serviceAction}</strong> {service_subcategory}{' '}
      </span>
    );
  } else if (service_action === 'triggered_review_process') {
    return (
      <span>
        {fullName} <strong>{serviceActionFormatted}</strong>
      </span>
    );
  } else if (
    service_action === 'for_correction' &&
    service_subcategory === LOGS_ENTITY_TYPES.mga_period
  ) {
    return (
      <span>
        {fullName} sent period <strong>{serviceActionFormatted}</strong>
      </span>
    );
  } else if (service_subcategory === LOGS_ENTITY_TYPES.mga_period) {
    return (
      <span>
        {fullName} <strong>{serviceActionFormatted}</strong> the period.
      </span>
    );
  } else if (service_subcategory === LOGS_ENTITY_TYPES.mga_action) {
    return (
      <span>
        {fullName} <strong>{serviceActionFormatted}</strong> the action.
      </span>
    );
  } else if (service_category === 'crew_planning') {
    const action = crewPlanningParsingActionName(service_action).replaceAll('_', ' ');
    const attributes = changedAttributes
      .map(attribute => fieldKeys[attribute])
      .filter(attribute => attribute);

    return (
      <span>
        {fullName}{' '}
        <strong>
          {attributes.length ? `updated ${attributes.map(attribute => ` ${attribute}`)}` : action}
        </strong>{' '}
        {(action === 'updated' && !attributes.length) || action === 'created'
          ? 'the Crew Rotation'
          : ''}
      </span>
    );
  } else if (service_category === 'vessel' && service_subcategory === 'document') {
    const vesselDocumentName =
      entityLog.entity_metadata?.display_name ||
      `${capitalizeFirst(serviceCategoryFormatted)} ${capitalizeFirst(service_subcategory)}`;

    return (
      <span>
        {fullName} <strong>{serviceActionFormatted}</strong> the {vesselDocumentName}.
      </span>
    );
  } else if (service_action === 'excel_exported') {
    return (
      <span>
        {fullName} <strong>exported Excel</strong> file on{' '}
        {capitalizeFirst(serviceCategoryFormatted)}.
      </span>
    );
  } else {
    return (
      <span>
        {fullName} <strong>{serviceActionFormatted}</strong> the {serviceCategoryFormatted}
      </span>
    );
  }
};
